import { useLayoutEffect } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import MarketingPage from "./components/MarketingPage";
import Saavutettavuusseloste from "./components/Saavutettavuusseloste";

// Scroll restoration
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) targetElement?.scrollIntoView({ block: "start" });
      else document.documentElement.scrollTo(0, 0);
    } else {
      document.documentElement.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return children;
};

export default function Router() {
  return (
    <HashRouter>
      <Wrapper>
        <Routes>
          <Route
            path="/saavutettavuusseloste"
            element={<Saavutettavuusseloste />}
          />
          <Route exact path="*" element={<MarketingPage />} />
        </Routes>
      </Wrapper>
    </HashRouter>
  );
}
