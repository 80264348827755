import i18next from "i18next";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import MetropoliaLogo from "../images/Metropolia_logo.png";
import TimelineSvgFi from "../images/Vallu_aikajana.svg";
import TimelineSvgSv from "../images/Vallu_aikajana_sve.svg";
import RemoteSvg from "../images/Vallu_etä_lähi_ikoni.svg";
import CapacitySvg from "../images/Vallu_kapasiteetti_ikoni.svg";
import ExamSvg from "../images/Vallu_koe_virta.svg";
import PersonSvg from "../images/Vallu_koetilanne_henkilö.svg";
import TailoredSvg from "../images/Vallu_räätälöinti_ikoni.svg";
import ValluLogoSvg from "../images/_Vallu_musta.svg";
import Wave from "./Wave";

export default function MarketingPage() {
  const { t } = useTranslation("common");

  return (
    <>
      <main className="container-fluid">
        <section
          className="row section-light fullHeight align-items-center"
          style={{ paddingBottom: "3rem" }}>
          <Col sm={1} xxl={2} className="d-none d-sm-block" />
          <Col xs={12} sm={10} md={6} xxl={5}>
            <h2 className="fw-bold fs-1">{t("marketing.title")}</h2>
            <p className="mt-5" style={{ fontSize: "1.15rem" }}>
              {t("marketing.infoText")}
            </p>
            <Row className="mt-4">
              {[
                { title: "2019", desc: t("marketing.fromYear") },
                { title: "540 000+", desc: t("marketing.examsDone") },
                { title: "1000+", desc: t("marketing.audition") }
              ].map((c) => (
                <Col xs={12} md={4} key={c.title} className="mb-4 mb-lg-0">
                  <div className="rounded-3 shadow-sm bg-white p-2 h-100 d-flex flex-column justify-content-between align-items-center">
                    <h3 className="fw-bold text-accent fs-2">{c.title}</h3>
                    <p className="m-0">{c.desc}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col sm={1} className="d-none d-sm-block d-md-none" />
          <Col sm={1} className="d-none d-sm-block d-md-none" />
          <Col xs={12} sm={10} md={4} xxl={3} className="ps-md-5">
            <div className="text-center text-lg-end">
              <img
                alt=""
                className="img-fluid"
                style={{ maxHeight: "25rem" }}
                src={ExamSvg}
              />
            </div>
          </Col>
          <Col sm={1} xxl={2} className="d-none d-md-block" />
        </section>

        <Wave direction="down" />

        <section
          className="row"
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}>
          <Col />
          <Col xs={12} sm={10} md={6} xxl={5} className="pe-5">
            <h2 className="fw-bold">{t("marketing.serviceDetails.title")}</h2>
            <p className="mt-5">
              <Trans
                defaults={t(`marketing.serviceDetails.infoText`)}
                components={{ Link: <a rel='noopener noreferrer' href={"https://testikoe.korkeakouluun.fi/#/#exercise-types"} target="_blank" /> }}
              />
            </p>
          </Col>

          <Col sm={1} className="d-none d-sm-block d-md-none" />
          <Col sm={1} className="d-none d-sm-block d-md-none" />

          <Col xs={12} sm={10} md={4} xxl={3} className="ps-md-5">
            <Row>
              <Col />
              <Col xs={12} xxl={8}>
                <div className="mt-5">
                  <img
                    alt=""
                    className="img-fluid"
                    style={{ maxHeight: "25rem" }}
                    src={PersonSvg}
                  />
                  <div className="mt-5">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://testikoe.korkeakouluun.fi/testikoe/examination.${i18next.language}.html`}
                      className="actionButton px-3 py-2 rounded-5 text-white text-decoration-none text-uppercase d-flex justify-content-center align-items-center">
                      {t("marketing.serviceDetails.actionButton")}
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col />
        </section>

        <Wave direction="up" />

        <section>
          <Row className="section-light" style={{ paddingTop: "5rem" }}>
            <Col />
            <Col xs={12} sm={10} xxl={8}>
              <h2 className="fw-bold">{t("marketing.serviceJourney.title")}</h2>
              <p className="mt-5">{t("marketing.serviceJourney.infoText")}</p>
            </Col>
            <Col />
          </Row>
          <Row className="section-light pt-4" style={{ paddingBottom: "5rem" }}>
            <Col />
            <Col xs={12} sm={10} xxl={8}>
              <div className="rounded-4 shadow-sm bg-white py-5 px-2 mt-3 text-center">
                <img
                  alt=""
                  className="img-fluid"
                  src={i18next.language == "sv" ? TimelineSvgSv : TimelineSvgFi}
                />
              </div>
            </Col>
            <Col />
          </Row>
        </section>

        <Wave direction="down" />

        <section>
          <Row style={{ paddingTop: "5rem" }}>
            <Col />
            <Col xs={12} sm={10} xxl={8}>
              <h2 className="fw-bold">
                {t("marketing.serviceCharacteristics.title")}
              </h2>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col />
            <Col xs={12} sm={10} xxl={8}>
              <p className="mt-5">
                {t("marketing.serviceCharacteristics.infoText")}
              </p>
            </Col>
            <Col />
          </Row>

          <Row style={{ paddingTop: "2rem", paddingBottom: "5rem" }}>
            <Col />
            <Col xs={12} sm={10} xxl={8}>
              <Row>
                {[
                  {
                    imgSrc: RemoteSvg,
                    desc: t("marketing.serviceCharacteristics.remote")
                  },
                  {
                    imgSrc: CapacitySvg,
                    desc: t("marketing.serviceCharacteristics.capacity")
                  },
                  {
                    imgSrc: TailoredSvg,
                    desc: t("marketing.serviceCharacteristics.tailored")
                  }
                ].map((c, idx) => (
                  <Col
                    key={`card-${idx}`}
                    xs={12}
                    sm={6}
                    lg={4}
                    className="mb-4 mb-lg-0">
                    <div
                      className="rounded px-3 h-100 py-1 d-flex justify-content-start align-items-center gap-3"
                      style={{ border: "2px dashed #3448FD" }}>
                      <img
                        alt=""
                        className="img-fluid px-2 py-2"
                        style={{ height: "4rem" }}
                        src={c.imgSrc}
                      />
                      <p className="m-0">{c.desc}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col />
          </Row>
        </section>

        <Wave direction="up" />

        <section
          className="row section-light"
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}>
          <Col />
          <Col xs={10} xxl={8}>
            <Row>
              <Col xs={12} sm={8} xxl={6}>
                <h2 className="fw-bold">
                  {t("marketing.serviceMoreInfo.title")}
                </h2>
                <p className="mt-5">
                  {t("marketing.serviceMoreInfo.infoText")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <p className="fw-bold m-0 mt-4">Tuomas Orama</p>
                <p className="m-0">Kehityspäällikkö</p>
                <p className="m-0">+358 50 401 3499</p>
                <p className="m-0">
                  <a href="mailto:tuomas.orama@metropolia.fi">
                    tuomas.orama@metropolia.fi
                  </a>
                </p>
              </Col>
              <Col xs={12} sm={6}>
                <Row className="h-100 d-flex align-items-end">
                  <Col className="d-none d-sm-block" />
                  <Col xs={6} sm={12} md={6} lg={4} xxl={4}>
                    <img
                      alt="Metropolia logo"
                      className="img-fluid mt-2 m-sm-0"
                      src={MetropoliaLogo}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col />
        </section>

        <Wave direction="down" />
      </main>
      <footer className="container-fluid">
        <Row
          className="py-4"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Col xs={6} className="order-1 order-sm-0 h-100 align-self-end">
            <img
              alt="Vallu logo"
              className="img-fluid"
              src={ValluLogoSvg}
              style={{ height: "3rem" }}
            />
          </Col>
          <Col xs={6} className="order-2 h-100 align-self-end text-end">
            <div className="d-flex flex-column flex-md-row justify-content-end align-items-end gap-3">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} Metropolia
              </p>
            </div>
          </Col>
        </Row>
      </footer>
    </>
  );
}
